<template>
  <div class="dashboard-container">
    <side-bar />
    <div class="main-content">
      <h2>Profile</h2>
      <hr class="h2" />
      <div class="container">
        <component src="https://c2hbe361.caspio.com/dp/76bfd000da963fb98c58443d9e41/emb" :is="'script'"></component>
      </div>
      <div class="container">
        <component src="https://c2hbe361.caspio.com/dp/76bfd000967d5d228a974024a0f5/emb" :is="'script'"></component>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
  name: 'UpdateProfile',
  components: {
    SideBar
  }
}
</script>
