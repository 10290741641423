<template>
    <div class="outer-container">
        <div class="container">
            <div class="column lhs">
                <div class="col-content">
                    <Vue3Lottie :animationData="blobJSON" :height="300" :width="300" />
                </div>
            </div>
            <div class="column rhs">
                <div class="col-content rhs">
                    <p class="title"><span class="large-text">Welcome to</span><br>Partner Portal</p>
                    <hr />
                    <p>First time user? <a href="https://www.trimateai.com.au/contact-us"><b>Contact us</b></a> to
                        get started!</p>
                    <router-link to="/partner" class="link">
                        Sign-in to Partner Portal
                    </router-link>
                    <p class="small-text">Are you a distributor? <router-link to="/distributor">
                            <b>Sign-in here</b>
                        </router-link></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie'
import blobJSON from '../assets/tria_blob_anim.json'

export default {
    name: 'HomeSignIn',
    components: {
        Vue3Lottie,
    },
    data() {
        return {
            blobJSON,
        }
    },
}
</script>


<style scoped>
.outer-container {
    background-color: #38434e;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
}

.container {
    display: flex;
    max-width: 980px;
    width: 100%;
}

.column {
    text-align: center;
    /* Center content within columns */
    align-content: center;
    /* Vertically center content within columns */
    margin: 0 auto;
}

.column.lhs {
    width: 40%;
}

.column.rhs {
    width: 60%;
}

.col-content {
    display: inline-block;
    vertical-align: middle;
    padding: 40px;
}

.col-content.rhs {
    max-width: 450px;
    min-height: 300px;
    text-decoration: none;
    border-radius: 20px;
    background-color: #f4f4f4;
    box-shadow: 4px 3px 8px rgba(0, 0, 0, 0.1);
    padding: 40px 40px 20px 40px;
}

p.title {
    font-size: 26px;
    margin: 20px 0px 10px 0px;
    font-family: 'Montserrat';
    text-align: center;
    line-height: 1.2;
    font-weight: bold;
}

.large-text {
    font-size: 36px;
}

.small-text {
    font-size: 14px;
}

.link {
    flex: 1;
    display: inline-block;
    margin-bottom: 40px;
    border-radius: 20px;
    padding: 10px 20px;
    background-color: #FE602F;
    box-shadow: 4px 3px 8px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    font-weight: bold;
}

.link:hover {
    background-color: black;
    color: white;
    font-weight: normal;
}

hr {
    background-color: #FE602F;
    border: none;
    margin-left: auto;
    margin-bottom: 20px;
    width: 60px;
    height: 3px;
}

a {
    color: black;
    text-decoration: none;
}

a:hover {
    color: #e45f2b;
}
</style>