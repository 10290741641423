<template>
    <div class="dashboard-container">
        <side-bar />
        <div class="main-content">
            <h2>Bots</h2>
            <hr class="h2" />
            <div class="container">
                <component src="https://c2hbe361.caspio.com/dp/76bfd00045c109ac0db248d7b02c/emb" :is="'script'">
                </component>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
    name: 'ViewBots',
    components: {
        SideBar
    }
}
</script>

<style scoped>
.container {
    margin-top: 25px;
}
</style>