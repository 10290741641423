<template>
  <div class="dashboard-container">
    <side-bar />
    <div class="main-content">
      <h1>VIEW BOT STATISTICS</h1>
      <hr class="h1"/>
      <div class="container">
        <component src="https://c2hbe361.caspio.com/dp/76bfd0005561c1297be24442b562/emb" :is="'script'"></component>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
  name: 'BotStatistics',
  components: {
    SideBar
  }
}
</script>