<template>
    <header>
        <div id="site-header">
            <a class="logo" href="/">
                <img class="t-logo" alt="Trimate logo" src="../assets/header-logo.webp">
                <span class="vortex-text" alt="Vortex logo">Vortex</span>
                <Vue3Lottie :animationData="blobJSON" :height="35" :width="35" />
            </a>
            <!--<nav>
                <ul>
                    <li><router-link to="/">Home</router-link></li>
                    <li><router-link to="/about">About</router-link></li>
                    <li><router-link to="/contact">Contact Us</router-link></li>
                </ul>
            </nav>-->
            <div class="btn logout-btn" v-if="logoutUrl">
                <a :href="logoutUrl" class="logout-button">LOGOUT</a>
            </div>
        </div>

    </header>
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie'
import blobJSON from '../assets/tria_blob_anim.json'

export default {
    computed: {
        logoutUrl() {
            return this.handleLogout().logoutUrl;
        }
    },
    methods: {
        handleLogout() {
            const currentRoute = this.$route.path;
            let logoutUrl;

            // Check if the route starts with 'customer', 'partner', or 'distributor'
            if (currentRoute.startsWith('/customer/')) {
                logoutUrl = 'https://c2hbe361.caspio.app/users/40v2e6/logout';
            } else if (currentRoute.startsWith('/partner/')) {
                logoutUrl = 'https://c2hbe361.caspio.app/users/t2h6sb/logout';
            } else if (currentRoute.startsWith('/distributor/')) {
                logoutUrl = 'https://c2hbe361.caspio.app/users/47m2t5/logout';
            }

            // Redirect to the appropriate URL
            return { logoutUrl };
        }
    },
    components: {
        Vue3Lottie,
    },
    data() {
        return {
            blobJSON,
        }
    },
}
</script>

<style scoped>
header {
    display: flex;
    border-bottom: 1px solid #ccc;
    padding: .5rem 1rem;
    background-color: black;

    p {
        margin-left: 1rem;
    }

    max-height: 80px;
}

#site-header {
    display: grid;
    width: 100%;
    height: 80px;
    grid-template-columns: auto auto auto auto auto auto;
}

.logo {
    grid-column-start: 2;
    grid-column-end: 2;
    align-content: center;
}

.logo img.t-logo,
img.v-text {
    max-height: 30px;
}

.logo img.t-logo {
    margin-right: 10px;
    margin-bottom: -3px;
}

.vortex-text {
    font-family: "Major Mono Display", monospace;
    font-size: 30px;
    font-weight: bold;
    -webkit-text-fill-color: transparent !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(135deg, #788e90, #FE602F);
    text-decoration: none;
}

.lottie-animation-container {
    display: inline-block;
    margin-bottom: -8px;
    margin-left: 5px;
}

nav {
    margin-left: auto;
    align-content: center;
    grid-column-start: 4;
    grid-column-end: 4;

    ul {
        list-style: none;
    }

    ul li {
        display: inline-flex;
        margin-left: 1rem;
    }

    ul li a {
        text-decoration: none;
        color: white;
    }

    ul li a:hover {
        cursor: pointer;
    }
}

.btn {
    background-color: #38434e;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 50px;
    cursor: pointer;
    width: 66px;
    height: 15px;
    align-self: center;
    margin: 0 20px;
    grid-column-start: 5;
}

.btn:hover {
    background-color: #e45f2b;
}

.btn a {
    text-decoration: none;
    color: white;
    font-size: 14px;
    vertical-align: super;
}
a.logo:-webkit-any-link {
    color: black;
}
</style>
