<template>
    <div class="dashboard-container">
        <side-bar />
        <div class="main-content">
            <h2>Partner Dashboard</h2>
            <hr class="h2" />
            <div class="flex-container">
                <div class="left-section">
                    <div class="container">
                        <component src="https://c2hbe361.caspio.com/dp/76bfd00030e4689da8684ba1bb94/emb" :is="'script'">
                        </component>
                    </div>
                </div>

                <div class="right-section">
                    <component src="https://c2hbe361.caspio.com/dp/76bfd000bd9e3a8991f2404bbc2d/emb" :is="'script'">
                    </component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
    name: 'PartnerDashboard',
    components: {
        SideBar,
    },
}
</script>

<style scoped>
h1 {
    margin-top: 10px;
}

.flex-container {
    display: flex;
    flex-direction: row;
}

.container {
    max-width: 980px;
    margin: 20px;
}

.tile {
    display: inline-block;
}

.left-section {
    max-width: 760px;
    width: 55%;
}

.right-section {
    max-width: 400px;
    width: 25%;
}
</style>