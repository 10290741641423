<template>
    <footer>
      <p>Copyright &copy; {{ year }} Trimate AI | All rights reserved</p>
    </footer>
  </template>

<script>
import { computed } from 'vue'

export default {
  setup () {
    const year = computed(() => new Date().getFullYear())
    return { year }
  }
}
</script>

<style scoped>
footer {
  background-color: black;
  width: 100%;
  max-height: 80px;
}
p {
  color: white;
}
</style>