<template>
  <div class="dashboard-container">
    <side-bar />
    <div class="main-content">
      <h2>New Distributor Sign-Up</h2>
      <hr class="h2"/>
      <div class="container">
        <component src="https://c2hbe361.caspio.com/dp/76bfd0005ad992eebe934f9dab1e/emb" :is="'script'"></component>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
  name: 'NewDistributor',
  components: {
    SideBar
  }
}
</script>