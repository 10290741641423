<template>
    <div>
        <h1>CONTACT US</h1>
        <hr class="h1"/>
    </div>
    <p>
        Contact our team via phone or email to get in touch with us. Check out our main website for more details:
        https://www.trimateai.com.au/
    </p>

</template>

<style>
h1 {
    margin: 60px 0px 10px 0px;
}
</style>