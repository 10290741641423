<template>
  <div class="dashboard-container">
    <side-bar />
    <div class="main-content">
      <div class="container">
        <component src="https://c2hbe361.caspio.com/dp/76bfd00097abb931c03c46848bd1/emb" :is="'script'"></component>
      </div>
      <h2>Edit  Bot Discovery
      </h2>
      <hr class="h2" />
      <div class="flex-container">
          <div class="container">
            <component src="https://c2hbe361.caspio.com/dp/76bfd0005ff86e6ddd4540928d38/emb" :is="'script'"></component>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
  name: 'EditBot',
  components: {
    SideBar,
  },
}
</script>

<style scoped>
.sidebar {
  max-width:10vw;
}
.flex-container {
  display: flex;
  flex-direction: row;
}


</style>