<template>
  <div class="dashboard-container">
    <side-bar />
    <div class="main-content">
      <h2>Distributor
        <button class="title-btn" @click="$router.push('/admin/new-distributor')">
          <font-awesome-icon icon="fa-user-plus" />
          New distributor
        </button>
      </h2>
      <hr class="h2"/>
      <div class="container">
        <component src="https://c2hbe361.caspio.com/dp/76bfd0004c32b047722b413f85ae/emb" :is="'script'"></component>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
  name: 'ManagePartners',
  components: {
    SideBar
  }
}
</script>