<template>
    <div>
        <h1>SUPER ADMIN SIGN IN</h1>
        <hr class="h1"/>
        <div>
            <Vue3Lottie :animationData="blobJSON" :height="200" :width="200" />
            <Vue3Lottie :animationData="loadingJSON" :height="50" :width="500" />
            <p>Please wait while we redirect you for authentication.</p>
        </div>
        <component src="https://c2hbe361.caspio.com/dp/76bfd0001b254ce56f864d19a5dc/emb" :is="'script'"></component>
    </div>

</template>

<script>
import { Vue3Lottie } from 'vue3-lottie'
import blobJSON from '../../assets/tria_blob_anim.json'
import loadingJSON from '../../assets/loading_anim.json'

export default {
    name: 'DistributorLogin',
    components: {
        Vue3Lottie,
    },
    data() {
        return {
            blobJSON,
            loadingJSON,
        }
    },
}
</script>

<style scoped>
h1 {
    text-align: center;
}
hr {
    margin-left:auto;
}
</style>