import { createApp } from 'vue';
import App from './App.vue';
import router from './router.js'; // Import your router
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue3Lottie from 'vue3-lottie'; //lottie blob animation

createApp(App)
    .use(router, Vue3Lottie)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app');

library.add(fas);
