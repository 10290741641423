<template>
  <div class="dashboard-container">
    <side-bar />
    <div class="main-content">
      <h1>VIEW MONTHLY BILLING DETAILS</h1>
      <hr class="h1"/>
      <div class="container">
        <component src="https://c2hbe361.caspio.com/dp/76bfd000574151e6f047473fafe1/emb" :is="'script'"></component>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
  name: 'ViewBills',
  components: {
    SideBar
  }
}
</script>