<template>
  <div class="dashboard-container">
    <side-bar />
    <div class="main-content">
      <h2>Partners
        <button class="title-btn" @click="$router.push('/distributor/new-partner')">
          <font-awesome-icon icon="fa-user-plus" />
          New partner
        </button>
      </h2>
      <hr class="h2"/>
      <div class="container">
        <component src="https://c2hbe361.caspio.com/dp/76bfd000a2b50f5a0c5f49d08755/emb" :is="'script'"></component>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
  name: 'ManagePartners',
  components: {
    SideBar
  }
}
</script>