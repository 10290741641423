<template>
    <div class="sidebar" :class="{ 'sidebar-collapsed': !isSidebarOpen }">
        <div class="sidebar-header">
            <button @click="toggleSidebar" class="sidebar-toggle">
                <font-awesome-icon icon="fa-bars" />
            </button>
        </div>
        <ul class="sidebar-nav">
            <li>
                <router-link to="/customer/dashboard" class="nav-link">
                    <font-awesome-icon icon="fa-home" v-b-tooltip.hover title="Dashboard"/>
                    <span v-if="isSidebarOpen">Dashboard</span>
                </router-link>
            </li>
            <li>
                <router-link to="/customer/update-profile" class="nav-link">
                    <font-awesome-icon icon="fa-user-edit" v-b-tooltip.hover title="Profile" />
                    <span v-if="isSidebarOpen">Profile</span>
                </router-link>
            </li>
            <li>
                <router-link to="/customer/bots" class="nav-link">
                    <font-awesome-icon icon="fa-robot" v-b-tooltip.hover title="Bots"/>
                    <span v-if="isSidebarOpen">Bots</span>
                </router-link>
            </li>
            <!-- <li>
                <router-link to="/customer/call-logs" class="nav-link">
                    <font-awesome-icon icon="fa-table-list" v-b-tooltip.hover title="Call Logs"/>
                    <span v-if="isSidebarOpen">Call Logs</span>
                </router-link>
            </li> -->
            <!-- <li>
                <router-link to="/customer/bot-statistics" class="nav-link">
                    <font-awesome-icon icon="fa-chart-line" v-b-tooltip.hover title="Bot Statistics"/>
                    <span v-if="isSidebarOpen">Bot Statistics</span>
                </router-link>
            </li> -->
            <!-- <li>
                <router-link to="/customer/billing" class="nav-link">
                    <font-awesome-icon icon="fa-file-invoice" v-b-tooltip.hover title="Billing History"/>
                    <span v-if="isSidebarOpen">Billing History</span>
                </router-link>
            </li> -->
            <li>
                <router-link to="/customer/conversation-history" class="nav-link">
                    <font-awesome-icon icon="fa-comments" v-b-tooltip.hover title="Conversation History"/>
                    <span v-if="isSidebarOpen">Conversation History</span>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
//import { ref } from 'vue';

export default {
    data() {
        return {
            isSidebarOpen: true,
        };
    },
    methods: {
        toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
    },
};
</script>

<style scoped>
.sidebar {
    width: 150px;
}
/* Style for collapsed sidebar */
.sidebar-collapsed {
    width: 40px;
}
</style>
