<template>
    <div class="sidebar" :class="{ 'sidebar-collapsed': !isSidebarOpen }">
        <div class="sidebar-header">
            <button @click="toggleSidebar" class="sidebar-toggle">
                <font-awesome-icon icon="fa-bars" />
            </button>
        </div>
        <ul class="sidebar-nav">
            <li>
                <router-link to="/partner/dashboard" class="nav-link">
                    <font-awesome-icon icon="fa-home" v-b-tooltip.hover title="Dashboard" />
                    <span v-if="isSidebarOpen">Dashboard</span>
                </router-link>
            </li>
            <li>
                <router-link to="/partner/opportunities" class="nav-link">
                    <font-awesome-icon icon="fa-bullseye" v-b-tooltip.hover title="Opportunities" />
                    <span v-if="isSidebarOpen">Opportunities</span>
                </router-link>
            </li>
            <li>
                <router-link to="/partner/update-profile" class="nav-link">
                    <font-awesome-icon icon="fa-user-edit" v-b-tooltip.hover title="Update Profile" />
                    <span v-if="isSidebarOpen">Profile</span>
                </router-link>
            </li>
            <li>
                <router-link to="/partner/customers" class="nav-link">
                    <font-awesome-icon icon="fa-users" v-b-tooltip.hover title="Manage Customers" />
                    <span v-if="isSidebarOpen">Customers</span>
                </router-link>
            </li>
            <!-- <li>
                <router-link to="/partner/customer-statistics" class="nav-link">
                    <font-awesome-icon icon="fa-chart-bar" v-b-tooltip.hover title="View Customer Statistics" />
                    <span v-if="isSidebarOpen">Statistics</span>
                </router-link>
            </li> -->
        </ul>
    </div>
</template>

<script>
//import { ref } from 'vue';

export default {
    data() {
        return {
            isSidebarOpen: true,
        };
    },
    methods: {
        toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
    },
};
</script>

<style scoped>
.sidebar {
    width: 150px;
}

/* Style for collapsed sidebar */
.sidebar-collapsed {
    width: 40px;
}

.sidebar-collapsed .nav-link {
    padding: 10px 15px;
    text-align: center;
}

.sidebar-collapsed .nav-link i {
    margin-right: 0;
}

.sidebar-collapsed .nav-link span {
    display: none;
}
</style>
