<template>
  <div class="flex-container">
    <app-header />
    <router-view />
    <app-footer />
  </div>
</template>


<script>
import AppHeader from './components/AppHeader.vue'
import AppFooter from './components/AppFooter.vue'

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter
  }
}
</script>

<style>
#app {
  font-family: 'Roboto', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  text-align: center;
}

body {
  margin: 0;
}

h1 {
  font-size: 40px;
  margin: 20px 0px 10px 0px;
  font-family: 'Montserrat';
  text-align: center;
}

h2 {
  font-size: 34px;
  margin: 20px 0px 10px 0px;
  font-family: 'Montserrat';
  text-align: left;
}
h3 {
  font-size: 18px;
  margin: 0px;
  font-family: 'Montserrat';
  text-align: left;
}

hr.h1, hr.h2 {
  background-color: #FE602F;
  border: none;
}

hr.h1 {
  width: 75px;
  height: 3px;
  margin-bottom: 40px;
}

hr.h2 {
  width: 60px;
  height: 3px;
  margin-left: 0;
}

p {
  font-family: 'Roboto';
  color: black;
  line-height: 1.5em;
}

.flex-container {
  display: flex;
  flex-direction: column;
  /* Stack elements vertically */
  min-height: 100vh;
  /* Ensure container takes up full viewport height */
}

.flex-container>* {
  flex: 1;
  /* Allow elements to grow and shrink */
}

.flex-container>footer {
  flex-shrink: 0;
  /* Prevent footer from shrinking */
}

.dashboard-container {
  display: flex;
  height: 100vh;
}

button {
  border: none;
  border-radius: 20px;
  color: #FFFFFF;
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  height: 34px;
  margin: 0px 10px;
  min-width: 180px;
  padding: 0px 20px;
  text-align: center;
  text-transform: uppercase;
  background-color: #38434e;
}

button:hover {
  background-color: #e45f2b;
}

.main-content {
  flex: 1;
  padding: 20px;
}

.sidebar {
  width: 250px;
  background-color: #f0f0f0;
  padding: 20px;
  transition: width 0.3s ease;
}

.sidebar-collapsed {
  width: 40px;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.sidebar-toggle,
.sidebar-toggle:hover {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #FE602F;
  width: auto;
  min-width: 20px;
  padding: 0px;
}

.sidebar-nav {
  list-style: none;
  padding: 0;
}

.sidebar-nav li {
  margin-bottom: 10px;
}

.nav-link {
  display: flex;
  align-items: center;
  padding: 10px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s ease;
}

.nav-link:hover {
  background-color: #ddd;
}

.nav-link span {
  margin-left: 10px;
  text-align: left;
}

.loading-gif {
  max-height: 150px;
}

.left-align {
  text-align: left;
  margin-bottom: 20px;
}

.SimpleButton {
  border: none !important;
  border-radius: 20px !important;
  background: #e5e5e5 !important;
  color: #6A7275 !important;
  display: inline-block !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  height: 34px !important;
  margin: 0px 10px !important;
  min-width: 180px !important;
  padding: 0px 20px !important;
  text-align: center !important;
  text-transform: uppercase !important;
  text-shadow: none !important;
  -webkit-text-shadow: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.SimpleButton.ActionButton {
  color: #FFFFFF !important;
  background: #e45f2b !important;
}

.SimpleButton:hover {
  color: #FFFFFF !important;
  background: black !important;
}

.Dialog .ButtonsCtnr,
#Alert .ButtonsCtnr {
  border-top: none !important;
  background-color: transparent !important;
}

.Dialog,
.Window,
#Alert {
  border: none !important;
}
.cbActionPanel {
  flex-direction: row-reverse;
}
.cbActionPanel ul li {
    color: white;
    background: #38434e ;
    padding: 10px 20px;
    border-radius: 20px;
    text-decoration: none;
}

.cbActionPanel ul li:hover {
    background: #e45f2b;
    color: white;
    text-decoration: none;
}
.cbFormSearchAgainLink {
  color: white !important;
}
.cbFormSearchAgainLink:hover {
  color: white !important;
  text-decoration: none !important;
}
.accent {
  color: #e45f2b !important;
}
.btn {
    background-color: #38434e;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 50px;
    cursor: pointer;
    min-width: 66px;
    height: 15px;
    align-self: center;
    margin: 0 20px;
    grid-column-start: 5;
    text-align: center !important;
    max-width: 160px;
}

.btn:hover {
    background-color: #e45f2b;
}

.btn a {
    text-decoration: none !important;
    color: white !important;
    font-size: 14px;
    vertical-align: super;  
}
</style>
