<template>
    <div class="sidebar" :class="{ 'sidebar-collapsed': !isSidebarOpen }">
        <div class="sidebar-header">
            <button @click="toggleSidebar" class="sidebar-toggle">
                <font-awesome-icon icon="fa-bars" />
            </button>
        </div>
        <ul class="sidebar-nav">
            <li>
                <router-link to="/admin/dashboard" class="nav-link">
                    <font-awesome-icon icon="fa-home" v-b-tooltip.hover title="Dashboard" />
                    <span v-if="isSidebarOpen">Dashboard</span>
                </router-link>
            </li>
            <li>
                <router-link to="/admin/update-profile" class="nav-link">
                    <font-awesome-icon icon="fa-user-edit" v-b-tooltip.hover title="Profile" />
                    <span v-if="isSidebarOpen">Profile</span>
                </router-link>
            </li>
            <li>
                <router-link to="/admin/opportunities" class="nav-link">
                    <font-awesome-icon icon="fa-bullseye" v-b-tooltip.hover title="Opportunities" />
                    <span v-if="isSidebarOpen">Opportunities</span>
                </router-link>
            </li>
            <!-- <li>
                <router-link to="/admin/new-partner" class="nav-link">
                    <font-awesome-icon icon="fa-user-plus" />
                    <span v-if="isSidebarOpen">New Partner Sign-up</span>
                </router-link>
            </li> -->
            <li>
                <router-link to="/admin/distributors" class="nav-link">
                    <font-awesome-icon icon="fa-users" v-b-tooltip.hover title="Distributors" />
                    <span v-if="isSidebarOpen">Distributors</span>
                </router-link>
            </li>
            <li>
                <router-link to="/admin/partners" class="nav-link">
                    <font-awesome-icon icon="fa-users" v-b-tooltip.hover title="Partners" />
                    <span v-if="isSidebarOpen">Partners</span>
                </router-link>
            </li>
            <li>
                <router-link to="/admin/releases" class="nav-link">
                    <font-awesome-icon icon="fa-bolt" />
                    <span v-if="isSidebarOpen">Release History</span>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
//import { ref } from 'vue';

export default {
    data() {
        return {
            isSidebarOpen: true,
        };
    },
    methods: {
        toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
    },
};
</script>

<style scoped>
.sidebar {
    width: 155px;
}

/* Style for collapsed sidebar */
.sidebar-collapsed {
    width: 40px;
}
</style>