<template>
  <div class="dashboard-container">
      <side-bar />
      <div class="main-content">
          <h2>Call Logs</h2>
          <hr class="h2" />
          <div class="container">
              <component src="https://c2hbe361.caspio.com/dp/76bfd0005f1a2c161a514846aac7/emb" :is="'script'">
              </component>
          </div>
      </div>
  </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
  name: 'ViewLogs',
  components: {
    SideBar
  }
}
</script>