<template>
    <div class="dashboard-container">
        <side-bar />
        <div class="main-content">
            <h2>Customer Dashboard</h2>
            <hr class="h2"/>
            <div class="container">
                <component src="https://c2hbe361.caspio.com/dp/76bfd000fbc2543499ef4f86b1c7/emb" :is="'script'">
                </component>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
    name: 'CustomerDashboard',
    components: {
        SideBar
    }
}
</script>

<style scoped>
.dashboard-container {
    display: flex;
    height: 100vh;
}

.main-content {
    flex: 1;
    padding: 20px;
    flex-grow: 1;
}

h1 {
    margin-top: 10px;
}

.container {
    display: flex;
    max-width: 980px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
}

.container-btn {
    display: flex;
    max-width: 980px;
    margin: 0 auto;
    padding: 20px;
    justify-content: flex-end;
}

.logout-button-container {
    display: flex;
}

.logout-button {
    background-color: #E6C7EB;
    color: black;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 50px;
    cursor: pointer;
    flex: 1;
}

.logout-button:hover {
    background-color: #e45f2b;
}
</style>