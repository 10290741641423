<template>
    <div class="dashboard-container">
        <side-bar />
        <div class="main-content">
            <h2>Super Admin Dashboard</h2>
            <hr class="h2"/>
            <div class="flex-container">
                <div class="left-section">
                    <div class="container">
                        <component src="https://c2hbe361.caspio.com/dp/76bfd000fe6026b351764faea732/emb" :is="'script'">
                        </component>
                    </div>
                </div>

                <div class="right-section">
                    <component src="https://c2hbe361.caspio.com/dp/76bfd000c268910c8def48c9b69b/emb" :is="'script'">
                    </component>
                </div>
            </div>            
        </div>
    </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
    name: 'DistributorDashboard',
    components: {
        SideBar
    }
}
</script>


<style scoped>
.dashboard-container {
    display: flex;
    height: 100vh;
}

.main-content {
    flex: 1;
    padding: 20px;
}

h1 {
    margin-top: 10px;
}

/* .container {
    display: flex;
    max-width: 980px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
}

.container-btn {
    display: flex;
    max-width: 980px;
    margin: 0 auto;
    padding: 20px;
    justify-content: flex-end;
}

.logout-button-container {
    display: flex;
}

.logout-button {
    background-color: #E6C7EB;
    color: black;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 50px;
    cursor: pointer;
    flex: 1;
}

.logout-button:hover {
    background-color: #FE602F;
} */
.flex-container {
    display: flex;
    flex-direction: row;
}

.container {
    max-width: 980px;
    margin: 20px;
}

.tile {
    display: inline-block;
}

.left-section {
    /* max-width: 760px; */
    width: 55%;
}

.right-section {
    max-width: 400px;
    width: 25%;
}
</style>