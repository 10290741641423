<template>
    <div class="dashboard-container">
        <side-bar />
        <div class="main-content">
            <!--Breadcrumbs and title embedded within below data page-->
            <div class="container">
                <component src="https://c2hbe361.caspio.com/dp/76bfd00014f8b8eb37eb4a46870e/emb" :is="'script'">
                </component>
            </div>
            <div class="tabs">
                <div class="tab-header">
                    <button class="tab-button" @click="activeTab = 'bots'"
                        :class="{ active: activeTab === 'bots' }">Bots</button>
                    <!-- <button class="tab-button" @click="activeTab = 'history'"
                        :class="{ active: activeTab === 'history' }">Conversation History</button>
                    <button class="tab-button" @click="activeTab = 'logs'"
                        :class="{ active: activeTab === 'logs' }">Call Logs</button> -->
                    <button class="tab-button" @click="activeTab = 'profile'"
                        :class="{ active: activeTab === 'profile' }">Profile</button>
                </div>
                <div class="tab-content">
                    <div v-if="activeTab === 'bots'">
                        <div class="container">
                            <component src="https://c2hbe361.caspio.com/dp/76bfd0000f373a959f324adba1be/emb"
                                :is="'script'">
                            </component>
                        </div>
                    </div>
                    <div v-if="activeTab === 'history'">
                        <!-- Content for History tab -->
                    </div>
                    <div v-if="activeTab === 'logs'">
                        <!-- Content for Documents tab -->
                    </div>
                    <div v-if="activeTab === 'profile'">
                        <div class="container">
                            <component src="https://c2hbe361.caspio.com/dp/76bfd000e03696ea2f524fe885bf/emb"
                                :is="'script'">
                            </component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
    name: 'CustomerDetails',
    components: {
        SideBar
    },
    data() {
        return {
            activeTab: 'bots' // Default active tab
        }
    }
}
</script>
<style scoped>
.tabs {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 980px;
}

.tab-header {
    text-align: left;
    margin-bottom: 20px;
}

.tab-button {
    border: none;
    cursor: pointer;
    background-color: transparent;
    border-radius: 0;
    color: black;
    border-bottom: 2px solid #38434e;
    text-transform: uppercase;
    font-size: 16px;
}

.tab-button.active {
    border-bottom: 2px solid #FE602F;
    color: #e45f2b;
}

.tab-content {
    padding: 20px;
}
</style>