<template>
    <div>
        <h1>ABOUT TRIMATEHUB</h1>
        <hr class="h1"/>
    </div>
    <p>
        This is a portal for Trimate AI customers, partners and distributors to manage their accounts, view bot analytics, and much more.
    </p>

</template>

<style>
h1 {
    margin: 60px 0px 10px 0px;
}
</style>
