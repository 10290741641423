<template>
    <div class="dashboard-container">
        <side-bar />
        <div class="main-content">
            <div class="container">
                <component src="https://c2hbe361.caspio.com/dp/76bfd000aec87c7310e84ca7b0b5/emb" :is="'script'">
                </component>
            </div>
            <h2>Data Collection</h2>
            <hr class="h2" />
            <div class="container">
                <component src="https://c2hbe361.caspio.com/dp/76bfd000da041f1bab4049069143/emb" :is="'script'">
                </component>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from '../SideBar.vue'

export default {
    name: 'BotData',
    components: {
        SideBar
    }
}
</script>