<template>
  <div class="dashboard-container">
    <side-bar />
    <div class="main-content">
      <h2>Opportunities
        <button v-on:click="showForm" v-if=!isFormOpen class="title-btn">
          <font-awesome-icon icon="fa-user-plus" />
          Add new opportunity
        </button>
      </h2>
      <hr class="h2" />
      <div class="container" v-if=isFormOpen>
        <component src="https://c2hbe361.caspio.com/dp/76bfd000869eac23796549018044/emb" :is="'script'"></component>
      </div>
      <br>
      <br>
      <!-- <div class="tabs">
        <ul>
          <li :class="{ active: currentTab === 'pipeline' }" @click="currentTab = 'pipeline'">Pipeline View</li>
          <li :class="{ active: currentTab === 'status' }" @click="currentTab = 'status'">Status Board</li>
        </ul>

        <div v-if="currentTab === 'pipeline'"> -->
      <div class="container">
        <component src="https://c2hbe361.caspio.com/dp/76bfd000f90b1364fd01448086a2/emb" :is="'script'"></component>
      </div>
      <!-- </div>

        <div v-if="currentTab === 'status'">
          <div class="container">
            <component src="https://c2hbe361.caspio.com/dp/76bfd000bd9e3a8991f2404bbc2d/emb" :is="'script'"></component>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
  name: 'NewOpportunities',
  components: {
    SideBar
  },
  data() {
    return {
      isFormOpen: false,
      currentTab: 'pipeline',
    };
  },
  methods: {
    showForm() {
      this.isFormOpen = true;
    },
  },
}
</script>
<style>
.title-btn {
  margin-left: 20px;
  vertical-align: middle;
}

.tabs {
  margin-top: 20px;
}

.tabs ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.tabs li {
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.tabs li.active {
  border-bottom: 3px solid #FE602F;
  font-weight: bold;
}
</style>