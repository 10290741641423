<template>
  <div class="dashboard-container">
    <side-bar />
    <div class="main-content">
      <h2>Update your profile</h2>
      <hr class="h2"/>
      <div class="container">
        <component src="https://c2hbe361.caspio.com/dp/76bfd000afb091485db142179553/emb" :is="'script'"></component>
      </div>
      <div class="container">
        <component src="https://c2hbe361.caspio.com/dp/76bfd000ca36f6244dd94a9688fd/emb" :is="'script'"></component>
      </div>
    </div>
  </div>
</template>


<script>
import SideBar from './SideBar.vue'

export default {
  name: 'UpdateProfile',
  components: {
    SideBar
  }
}
</script>